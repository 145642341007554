import qrow from './qrow.jpg';
import './App.css';

function App() {
  return (
    <>
    <header>
      <h1>Qrow</h1>
    </header>
      <div className="App">
        <h2>{window.location.hostname}</h2>
        <p>This domain is the property of <a href="//hacia.net" target="_blank" rel="noreferrer">Hacia.net</a></p>
        <p>This website is not affiliated with RWBY or Rooster Teeth in any way. This is merely an undeveloped fandom domain.</p>
        <h2>What is RWBY?</h2>
        <p>RWBY is an animated series created by Monty Oum, known for its unique blend of action, fantasy, and storytelling. Set in the fictional world of Remnant, the show follows the adventures of four girls—Ruby Rose, Weiss Schnee, Blake Belladonna, and Yang Xiao Long—as they train to become Huntsmen, warriors who protect humanity from supernatural creatures called Grimm. One prominent character in the series is Qrow Branwen, a skilled Huntsman and uncle to Ruby and Yang.</p>
        <h2>Who is Qrow Branwen?</h2>
        <p>Qrow Branwen is an enigmatic and complex character, often portrayed as a lone wolf with a troubled past. He is a skilled fighter who wields a unique weapon—a transforming scythe known as Harbinger. Qrow's combat abilities are exceptional, and he possesses a Semblance, a unique power that grants him good luck. However, his Semblance also brings misfortune to those around him, leading to his self-imposed isolation.</p>
        <p>Despite his gruff exterior, Qrow deeply cares for his nieces and is fiercely protective of them. He becomes an influential mentor figure, guiding Ruby and Yang in their journey as Huntresses. Qrow's experiences have made him cynical and mistrustful of authority, often taking a more rebellious and independent approach. He is known for his dry wit, sardonic humor, and love for alcohol, which is also a way for him to cope with his personal demons.</p>
        <p>Qrow's past is shrouded in secrecy, and he is associated with the Branwen family, who have a reputation for being outcasts. As the series progresses, more details about his history are revealed, shedding light on his motivations and struggles. Qrow's presence adds depth to the story, as his experiences and interactions with other characters bring a sense of maturity and complexity to the narrative. With his unique combat skills, complex personality, and mysterious backstory, Qrow Branwen is a beloved and memorable character in the world of RWBY.</p>
      </div>
      <img src={qrow} className="qrow-img" alt="Qrow" />
    </>
  );
}

export default App;
